//TODO: LAv inpage search form
e4.search = {
    init: function () {
        if ( jQuery( '.js-e-search' ).length > 0 ) {
            e4.util.require.js( e4.settings.require.handlebars, function () {
                e4.handlebars.registerHelpers( 'loop' );
                e4.handlebars.registerTemplates( '#js-e-handlebars-tmpl-search-result-item-product #js-e-handlebars-tmpl-search-result-item-page' );
                e4.search.bindEvents();
                if ( e4.util.hash.get( 'search' ) !== undefined ) {
                    jQuery( '.js-e-search-modal' ).filter( ':not(.show)' ).modal( 'show' );
                }
            } );
        }
    },
    bindEvents: function () {
        jQuery( '.js-e-search-modal' ).on( 'shown.bs.modal hide.bs.modal', function ( event ) {
            e4.search.handleModalState( this, event );
        } );
        jQuery( '.js-e-search-form' ).on( 'submit', function ( event ) {
            event.preventDefault();
        } );
        jQuery( '.js-e-search-input' ).on( 'keyup input', function ( event ) {
            e4.search.toggleInputGroupState( this, event );
            e4.search.result.get( this, event );
        } ).on( 'change', function () {
            if ( this.value !== '' && e4.util.hash.get( 'search' ) !== undefined ) {
                e4.util.hash.replace( 'search', this.value );
                e4.search.result.track( this, this.value );
            }
        } );
        jQuery( '.js-e-search-clear' ).on( 'click', function () {
            e4.search.clearInput( this );
        } );
        jQuery( window ).on( 'hashchange', function () {
            var hashSearch = e4.util.hash.get( 'search' );
            if ( hashSearch === undefined ) {
                jQuery( '.js-e-search-modal' ).filter( '.show' ).modal( 'hide' );
            }
            else {
                jQuery( '.js-e-search-modal' ).filter( ':not(.show)' ).modal( 'show' );
            }
        } );
    },
    loadIndex: function () {
        if ( e4.settings.search.result.products.show === true ) {
            jQuery.get( e4.settings.search.result.products.url + ( new Date().getTime() ) );
        }
        if ( e4.settings.search.result.pages.show === true ) {
            jQuery.get( e4.settings.search.result.pages.url + ( new Date().getTime() ) );
        }
    },
    handleModalState: function ( domElm, event ) {
        var jSearchInput = jQuery( domElm ).find( '.js-e-search-input' ),
            strQuery = jSearchInput.val(),
            hashSearch = e4.util.hash.get( 'search' );
        if ( event.type === 'shown' ) {
            if ( hashSearch !== undefined && hashSearch !== '' ) {
                jSearchInput.val( hashSearch ).triggerHandler( 'keyup' );
            }
            else if ( strQuery !== '' ) {
                e4.util.hash.set( 'search', strQuery );
                jSearchInput.triggerHandler( 'keyup' );
            }
            else {
                e4.util.hash.set( 'search' );
                e4.search.loadIndex();
            }
            jSearchInput.trigger( 'focus' );
        }
        else {
            jSearchInput.trigger( 'blur' );
            if ( hashSearch !== undefined ) {
                e4.util.hash.remove( 'search' );
                //console.log( 'remove on hash modal hide' );
            }
        }
    },
    toggleInputGroupState: function ( domElm ) {
        var jSearchInputGroup = jQuery( domElm ).closest( '.js-e-search-input-group' );
        if ( domElm.value === '' ) {
            jSearchInputGroup.addClass( 'is-empty' );
        }
        else {
            jSearchInputGroup.removeClass( 'is-empty' );
        }
    },
    clearInput: function ( domElm ) {
        jQuery( domElm ).closest( '.js-e-search-input-group' ).find( '.js-e-search-input' ).val( '' ).trigger( 'focus' ).triggerHandler( 'keyup' );
    },
    result: {
        timeout: null,
        loading: false,
        ready: false,
        count: 0,
        get: function ( domElm ) {
            var strQuery = jQuery.trim( domElm.value );
            if ( e4.search.result.timeout !== null ) {
                clearTimeout( e4.search.result.timeout );
                e4.search.result.timeout = null;
                e4.search.result.loading = false;
            }
            if ( strQuery === '' ) {
                e4.search.result.hide( domElm );
            }
            else if ( strQuery !== domElm.getAttribute( 'data-current-query' ) ) {
                e4.search.result.loading = true;
                var jSearch = jQuery( domElm ).closest( '.js-e-search' );
                jSearch.find( '.js-e-search-result-container' ).addClass( 'is-active' );
                jSearch.find( '.js-e-search-loading-overlay' ).addClass( 'is-loading' );
                e4.search.result.timeout = setTimeout( function () {
                    e4.search.result.ready = false;
                    e4.search.result.count = 0;
                    domElm.setAttribute( 'data-current-query', strQuery );
                    if ( e4.settings.search.result.products.show === true ) {
                        e4.search.result.products.get( domElm );
                    }
                    if ( e4.settings.search.result.pages.show === true ) {
                        e4.search.result.pages.get( domElm );
                    }
                }, Math.round( Math.max( e4.settings.search.delay.min, e4.settings.search.delay.max / strQuery.length ) ) );
            }
        },
        show: function ( domElm ) {
            //console.log( 'show', e4.search.result.timeout );
            if ( e4.search.result.isReady() === true ) {
                e4.search.result.loading = false;
                e4.search.result.count = e4.search.result.pages.count + e4.search.result.products.count;
                //console.log( domElm.value, e4.search.result.count, e4.search.result.pages.count, e4.search.result.products.count );
                var jSearch = jQuery( domElm ).closest( '.js-e-search' );
                jSearch.find( '.js-e-search-result-count' ).text( e4.search.result.count );
                jSearch.find( '.js-e-search-loading-overlay' ).removeClass( 'is-loading' );
            }
        },
        hide: function ( domElm ) {
            jQuery( domElm ).closest( '.js-e-search' ).find( '.js-e-search-result-container' ).removeClass( 'is-active' );
            e4.search.result.products.hide( domElm );
            e4.search.result.pages.hide( domElm );
        },
        isReady: function () {
            return ( ( e4.search.result.pages.ready === true || e4.settings.search.result.pages.show === false ) && ( e4.search.result.products.ready === true || e4.settings.search.result.products.show === false ) );
        },
        isLoading: function () {
            return ( e4.search.result.ready === false || e4.search.result.loading === true );
        },
        track: function ( domElm, strQuery ) {
            // console.log( '---------------' );
            // console.log( 'strQuery', strQuery );
            // console.log( 'domElm.getAttribute( data-current-query )', domElm.getAttribute( 'data-current-query' ) );
            // console.log( 'e4.search.result.ready', e4.search.result.ready );
            // console.log( 'e4.search.result.count', e4.search.result.count );
            // console.log( 'e4.search.result.isLoading()', e4.search.result.isLoading(), e4.search.result.timeout );
            if ( e4.search.result.isLoading() === true ) {
                //console.log( 'track timeout', strQuery );
                setTimeout( function () {
                    e4.search.result.track( domElm, strQuery );
                }, 100 );
            }
            else if ( strQuery === domElm.getAttribute( 'data-current-query' ) ) {
                //console.log( 'track query', strQuery );
                e4.gtm.pushDataLayer( {
                    'event': 'VirtualPageviewSearch',
                    'virtualPageSearchURL': '/search?q=' + strQuery,
                    'virtualPageSearchTitle': 'Search'
                } );
                if ( e4.search.result.count === 0 ) {
                    //console.log( 'track no results', strQuery );
                    e4.gtm.pushDataLayer( {
                        'eventCategory': 'Nulsøgning',
                        'eventAction': strQuery,
                        'eventLabel': '{{Page URL}}',
                        'eventValue': 0,
                        'eventInteraction': true,
                        'event': 'track-ga-event'
                    } );
                }
            }
        },
        products: {
            ready: false,
            count: 0,
            get: function ( domElm ) {
                e4.search.result.products.ready = false;
                e4.search.result.products.count = 0;
                jQuery.getJSON( e4.settings.search.result.products.url + domElm.value, function ( data ) {
                    //console.log('products', domElm.getAttribute( 'data-current-query' ), data.q);
                    if ( domElm.getAttribute( 'data-current-query' ) === data.q ) {
                        e4.search.result.products.ready = true;
                        e4.search.result.ready = e4.search.result.isReady();
                        e4.search.result.products.count = data.products.length;
                        e4.search.result.products.show( domElm, data );
                        e4.search.result.show( domElm );
                    }
                } );
            },
            show: function ( domElm, data ) {
                if ( e4.search.result.products.count > 0 ) {
                    var jSearch = jQuery( domElm ).closest( '.js-e-search' ),
                        intMaxResults = ( e4.settings.search.result.pages.show === true ) ? e4.settings.search.result.products.max[ e4.util.getBreakPoint() ] : 60,
                        dataSliced = {
                            products: data.products.slice( 0, intMaxResults )
                        };
                    jSearch.find( '.js-e-search-result-products' ).html( e4.handlebars.tmpl.searchResultItemProduct( dataSliced ) ).removeClass( 'is-empty' );
                    e4.search.result.products.toggleShowAll( domElm, data, intMaxResults );
                }
                else {
                    e4.search.result.products.hide( domElm );
                }
            },
            hide: function ( domElm ) {
                jQuery( domElm ).closest( '.js-e-search' ).find( '.js-e-search-result-products' ).addClass( 'is-empty' ).html( '' );
            },
            toggleShowAll: function ( domElm, data, intMaxResults ) {
                var jShowAll = jQuery( domElm ).closest( '.js-e-search' ).find( '.js-e-search-products-show-all' );
                if ( e4.search.result.products.count > intMaxResults ) {
                    jShowAll.find( 'a' ).attr( 'href', e4.settings.search.result.products.showAllLink + domElm.value ).on( 'click', {
                        'domElm': domElm,
                        'products': data
                    }, e4.search.result.products.showAll );
                }
                else {
                    jShowAll.addClass( 'd-none' );
                    jShowAll.find( 'a' ).off( 'click', e4.search.result.products.showAll );
                }
            },
            showAll: function ( event ) {
                if ( e4.search.result.products.count > 0 && event.data !== undefined ) {
                    event.preventDefault();
                    var jSearch = jQuery( event.data.domElm ).closest( '.js-e-search' );
                    jSearch.find( '.js-e-search-result-products' ).html( e4.handlebars.tmpl.searchResultItemProduct( event.data.products ) ).removeClass( 'is-empty' );
                    jSearch.find( '.js-e-search-products-show-all' ).addClass( 'd-none' );

                }
            }
        },
        pages: {
            ready: false,
            count: 0,
            get: function ( domElm ) {
                e4.search.result.pages.ready = false;
                e4.search.result.pages.count = 0;
                jQuery.getJSON( e4.settings.search.result.pages.url + domElm.value, function ( data ) {
                    //console.log('pages', domElm.getAttribute( 'data-current-query' ), data.q);
                    if ( domElm.getAttribute( 'data-current-query' ) === data.q ) {
                        e4.search.result.pages.ready = true;
                        e4.search.result.ready = e4.search.result.isReady();
                        e4.search.result.pages.count = data.pages.length;
                        e4.search.result.pages.show( domElm, data );
                        e4.search.result.show( domElm );
                    }
                } );
            },
            show: function ( domElm, data ) {
                if ( e4.search.result.pages.count > 0 ) {
                    var jSearch = jQuery( domElm ).closest( '.js-e-search' );
                    jSearch.find( '.js-e-search-result-pages' ).html( e4.handlebars.tmpl.searchResultItemPage( data ) ).removeClass( 'is-empty' );
                }
                else {
                    e4.search.result.pages.hide( domElm );
                }
            },
            hide: function ( domElm ) {
                var jSearch = jQuery( domElm ).closest( '.js-e-search' );
                jSearch.find( '.js-e-search-result-pages' ).addClass( 'is-empty' ).html( '' );
            }
        }
    }
};

