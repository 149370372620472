( function () {
    'use strict';
    jQuery( function () {
        e4.nav.init();
        e4.breadcrumb.init();
        e4.forms.init();
        e4.ecom.cart.init();
    } );
}() );

