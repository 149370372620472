e4.p.card = {
    init: function () {
        e4.p.card.bindEvents();
    },
    bindEvents: function () {
        jQuery( '.js-p-card-link' ).on( 'click', function ( event ) {
            var jCardLink = jQuery( this ).find( 'a[href]' );
            if ( jCardLink.length === 1 && /input|textarea/i.test( event.target.tagName ) === false ) {
                if ( jCardLink.attr( 'target' ) === '_blank' ) {
                    window.open( jCardLink.attr( 'href' ) );
                }
                else {
                    location.href = jCardLink.attr( 'href' );
                }
                //TODO: Trigger click handler.
            }
        } );
    }
};

