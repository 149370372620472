e4.news = {
    init: function () {
        e4.news.filter.init();
        e4.news.paging();
    },
    filter: {
        timeout: null,
        init: function () {
            if ( jQuery( '.js-e-news-filter' ).length > 0 ) {
                e4.news.filter.bindEvents();
            }
        },
        bindEvents: function () {
            jQuery( '.js-e-news-filter' ).on( 'click', '.js-e-news-filter-option', function ( event ) {
                event.preventDefault();
                e4.news.filter.toggleControls( this );
                e4.news.filter.apply( this );
            } );
        },
        toggleControls: function ( domElm ) {
            var jThis = jQuery( domElm );
            if ( jThis.data( 'filter' ) === '*' ) {
                jThis.addClass( 'active' );
                jThis.closest( '.js-e-news-filter' ).find( '.js-e-news-filter-option' ).not( '[data-filter="*"]' ).removeClass( 'active' );
            }
            else {
                jThis.toggleClass( 'active' );
                jThis.closest( '.js-e-news-filter' ).find( '.js-e-news-filter-option' ).filter( '[data-filter="*"]' ).removeClass( 'active' );
            }
        },
        apply: function ( domElm ) {
            var arrSelectorFilter = [],
                jThis = jQuery( domElm );
            jThis.closest( '.js-e-news-filter' ).find( '.js-e-news-filter-option' ).filter( '.active' ).each( function () {
                arrSelectorFilter.push( this.getAttribute( 'data-filter' ) );
            } );
            if ( arrSelectorFilter.length === 0 ) {
                arrSelectorFilter.push( '*' );
                jThis.closest( '.js-e-news-filter' ).find( '.js-e-news-filter-option' ).filter( '[data-filter="*"]' ).addClass( 'active' );
            }
            jThis.closest( '.js-e-news' ).css( 'min-height', function () {
                return this.scrollHeight;
            } ).addClass( 'is-filtering' );
            if ( e4.news.filter.timeout !== null ) {
                clearTimeout( e4.news.filter.timeout );
                e4.news.filter.timeout = null;
            }
            e4.news.filter.timeout = setTimeout( function () {
                jThis.closest( '.js-e-news' ).find( '.js-e-news-item' ).removeClass( 'd-none' ).not( arrSelectorFilter.join( ',' ) ).addClass( 'd-none' );
                jQuery( '.js-e-news' ).removeClass( 'is-filtering' ).css( {
                    'min-height': 0
                } );
            }, e4.settings.animation.speed );

        }
    },
    paging: function () {
        var jNewsPaging = jQuery( '.js-e-news-pagination' );
        if ( jNewsPaging.length === 1 ) {
            jNewsPaging.find( 'a' ).not( '.js-e-news-pagination-item' ).addClass( 'page-link' );
            var jNewsPagingList = jQuery( '.js-e-news-pagination-list-dw' ).find( 'a' ),
                jNewsPagingItemTemplate = jQuery( '.js-e-news-pagination-item' ),
                jNewsPagingListNew = [];
            jNewsPagingList.each( function () {
                var jThis = jQuery( this ),
                    jNewsPagingItemNew = jNewsPagingItemTemplate.clone();
                jNewsPagingItemNew.find( 'a' ).attr( 'href', jThis.attr( 'href' ) ).text( jThis.text() );
                if ( jThis.find( 'b' ).length === 1 ) {
                    jNewsPagingItemNew.addClass( 'active' );
                }
                jNewsPagingListNew.push( jNewsPagingItemNew );
            } );
            jNewsPagingItemTemplate.replaceWith( jNewsPagingListNew );
        }
    }
};

