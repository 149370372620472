// https://api.jquery.com/jquery.extend/#jQuery-extend-deep-target-object1-objectN
// ( function () {
//     'use strict';
//     jQuery.extend( true, e4.settings, {
//         nav: {
//             flagCDN: 'https://cdn.jsdelivr.net/npm/flag-icon-css@2.8.0/flags/4x3/'
//         }
//     } );
// }() );

