e4.nav = {
    init: function () {
        e4.nav.global.init();
        e4.nav.local.horizontal.init();
        e4.nav.local.vertical.init();
        e4.nav.util.init();
    },
    global: {
        init: function () {
            //If the page has sticky local navigation, the global nav cannot be sticky.
            if ( jQuery( '.e-nav-local.is-sticky:visible' ).length > 0 ) {
                //TODO: Lav check for om local nav er synlig i current breakpoint.
                e4.nav.global.disableSticky();
            }
            e4.nav.global.lastScrollTop = jQuery( window ).scrollTop();
            if ( e4.settings.nav.autoHideTolerance.down === 'auto' ) {
                e4.settings.nav.autoHideTolerance.down = jQuery( '.js-e-header' ).height();
            }
            e4.settings.nav.autoHide = ( jQuery( '.js-e-page-auto-hide-menu' ).length === 1 );
            e4.nav.global.requestingAnimation = false;
            e4.nav.global.bindEvents();
        },
        bindEvents: function () {
            var jNavContainer = jQuery( '.js-e-nav-container' );
            jNavContainer.on( 'swipeleft', function () {
                jNavContainer.modal( 'hide' );
            } );
            jNavContainer.on( 'shown.bs.modal', function () {
                e4.nav.global.revealActive();
            } );
            jNavContainer.on( 'hide.bs.modal', function () {
                jQuery( '.js-e-nav-container' ).scrollTop( 0 );
                jQuery( '.js-e-nav-container' ).removeAttr( 'style' );
                jQuery( '.js-e-nav-global' ).find( '.nav-link.is-inpath' ).filter( '[data-collapse-target]' ).each( function () {
                    jQuery( this.getAttribute( 'data-collapse-target' ) ).removeClass( 'show' );
                } );
            } );
            jNavContainer.on( 'click', 'a', function ( event ) {
                //TODO: Lægges ud i funktion.
                var jThis = jQuery( this );
                if ( jThis.is( '.show [data-toggle="dropdown"]' ) === true ) {
                    event.stopPropagation();
                }
                if ( jThis.is( '.show [data-collapse-toggle="true"]' ) === true ) {
                    event.preventDefault();
                    jQuery( this.getAttribute( 'data-collapse-target' ) ).collapse( 'toggle' );
                }
            } );
            if ( jQuery( '.js-e-page-auto-hide-menu' ).length === 1 ) {
                jQuery( window ).on( 'scroll', function () {
                    if ( e4.nav.global.requestingAnimation === false ) {
                        window.requestAnimationFrame( function () {
                            e4.nav.global.toggleSticky();
                            if ( e4.settings.nav.autoHide ) {
                                e4.nav.global.toggleVisibility();
                            }
                            e4.nav.global.requestingAnimation = false;
                        } );
                    }
                    e4.nav.global.requestingAnimation = true;
                } );
            }
        },
        show: function () {
            jQuery( '.js-e-header' ).removeClass( 'is-hidden' );
        },
        hide: function () {
            jQuery( '.js-e-header' ).addClass( 'is-hidden' );
        },
        toggleVisibility: function () {
            var windowScrollTop = jQuery( window ).scrollTop();
            e4.nav.global.scrollDirectionCurrent = windowScrollTop > e4.nav.global.lastScrollTop ? 'down' : 'up';
            if ( windowScrollTop <= 0 ) { // Check for iOS scroll bounce
                e4.nav.global.show();
            }
            else if ( Math.abs( windowScrollTop - e4.nav.global.lastScrollTop ) > e4.settings.nav.autoHideTolerance[ e4.nav.global.scrollDirectionCurrent ] ) {
                if ( e4.nav.global.scrollDirectionCurrent === 'down' && e4.nav.global.scrollDirectionLast !== 'down' ) {
                    if ( jQuery( '.js-e-header' ).find( '.dropdown.show' ).length < 1 ) {
                        e4.nav.global.hide();
                    }
                }
                else if ( e4.nav.global.scrollDirectionCurrent === 'up' && e4.nav.global.scrollDirectionLast !== 'up' ) {
                    e4.nav.global.show();
                }
                e4.nav.global.lastScrollTop = windowScrollTop;
                e4.nav.global.scrollDirectionLast = e4.nav.global.scrollDirectionCurrent;
            }
        },
        toggleSticky: function () {
            //var intOffsetTop = e4.nav.local.vertical.domElm.getBoundingClientRect().top;
            if ( jQuery( window ).scrollTop() > 0 ) {
                jQuery( '.js-e-header' ).addClass( 'is-stuck' );
            }
            else {
                jQuery( '.js-e-header' ).removeClass( 'is-stuck' );
            }
        },
        disableSticky: function () {
            jQuery( '.js-e-header' ).removeClass( 'is-sticky' );
        },
        revealActive: function () {
            var jNavContainer = jQuery( '.js-e-nav-container' ),
                jNavItemActive = jNavContainer.find( '.nav-item.is-active' ).last(),
                jNavItemActiveParent = jNavItemActive.closest( '.nav' ).closest( '.nav-item' ),
                jOffsetElement = jNavItemActiveParent,
                intScrollTarget = 0;
            if ( jNavItemActive.length === 1 ) {
                // TODO: open inpath collapsed elements
                jQuery( '.js-e-nav-global' ).find( '.nav-link.is-inpath' ).filter( '[data-collapse-target]' ).each( function () {
                    jQuery( this.getAttribute( 'data-collapse-target' ) ).addClass( 'show' );
                } );
                setTimeout( function () {
                    // Hvis parent item ikke findes, eller er for høj til viewport, sættes offset element til det aktive item.
                    if ( jNavItemActiveParent.length === 0 || jNavItemActiveParent.height() > jNavContainer.height() ) {
                        jOffsetElement = jNavItemActive;
                    }
                    // Beregn relativt offset til containeren.
                    intScrollTarget = jOffsetElement.offset().top - jNavContainer.offset().top;
                    jNavContainer.animate( {
                        scrollTop: intScrollTarget
                    }, Math.floor( intScrollTarget * e4.settings.nav.revealActive.speed ), e4.settings.nav.revealActive.easing );

                }, e4.settings.nav.revealActive.delay );
            }
        }
    },
    local: {
        horizontal: {
            init: function () {
                var jNavLocalHorizontal = jQuery( '.js-e-nav-local-horizontal.is-sticky' );
                if ( jNavLocalHorizontal.length === 1 ) {
                    //e4.nav.local.horizontal.domElm = jNavLocalHorizontal.get( 0 );
                    e4.nav.local.horizontal.requestingAnimation = false;
                    jNavLocalHorizontal.removeClass( 'is-sticky' );
                    e4.nav.local.horizontal.intialOffsetTop = jNavLocalHorizontal.offset().top;
                    jNavLocalHorizontal.addClass( 'is-sticky' );
                    e4.nav.local.horizontal.bindEvents();
                    e4.nav.local.horizontal.toggleSticky();
                }
            },
            bindEvents: function () {
                jQuery( window ).on( 'scroll', function () {
                    if ( e4.nav.local.horizontal.requestingAnimation === false ) {
                        window.requestAnimationFrame( function () {
                            e4.nav.local.horizontal.toggleSticky();
                            e4.nav.local.horizontal.requestingAnimation = false;
                        } );
                    }
                    e4.nav.local.horizontal.requestingAnimation = true;
                } );
            },
            toggleSticky: function () {
                //var intOffsetTop = e4.nav.local.horizontal.domElm.getBoundingClientRect().top;
                if ( jQuery( window ).scrollTop() > e4.nav.local.horizontal.intialOffsetTop ) {
                    jQuery( '.js-e-nav-local-horizontal' ).addClass( 'is-stuck' );
                }
                else {
                    jQuery( '.js-e-nav-local-horizontal' ).removeClass( 'is-stuck' );
                }
            }
        },
        vertical: {
            init: function () {
                var jNavLocalVertical = jQuery( '.js-e-nav-local-vertical' );
                if ( jNavLocalVertical.length === 1 ) {
                    if ( jNavLocalVertical.is( '.is-sticky' ) === true ) {
                        if ( jNavLocalVertical.outerHeight( true ) > jQuery( window ).height() ) {
                            jNavLocalVertical.removeClass( 'is-sticky' );
                        }
                        else {
                            //e4.nav.local.vertical.domElm = jNavLocalVertical.get( 0 );
                            e4.nav.local.vertical.requestingAnimation = false;
                            jNavLocalVertical.removeClass( 'is-sticky' );
                            e4.nav.local.horizontal.intialOffsetTop = jNavLocalVertical.offset().top;
                            jNavLocalVertical.addClass( 'is-sticky' );
                            e4.nav.local.vertical.bindEvents();
                            e4.nav.local.vertical.toggleSticky();
                        }
                    }
                    e4.nav.local.vertical.setContentHeight();
                }
            },
            bindEvents: function () {
                jQuery( window ).on( 'scroll', function () {
                    if ( e4.nav.local.vertical.requestingAnimation === false ) {
                        window.requestAnimationFrame( function () {
                            e4.nav.local.vertical.toggleSticky();
                            e4.nav.local.vertical.requestingAnimation = false;
                        } );
                    }
                    e4.nav.local.vertical.requestingAnimation = true;
                } );
            },
            toggleSticky: function () {
                //var intOffsetTop = e4.nav.local.vertical.domElm.getBoundingClientRect().top;
                if ( jQuery( window ).scrollTop() > e4.nav.local.vertical.intialOffsetTop ) {
                    jQuery( '.js-e-nav-local-vertical' ).addClass( 'is-stuck' );
                }
                else {
                    jQuery( '.js-e-nav-local-vertical' ).removeClass( 'is-stuck' );
                }
            },
            setContentHeight: function () {
                jQuery( '.e-content-main' ).css( 'min-height', function () {
                    var intNavLocalHeight = parseInt( jQuery( '.js-e-nav-local-vertical' ).outerHeight( true ) ) || 0,
                        intContentPaddingTop = parseInt( window.getComputedStyle( this ).getPropertyValue( 'padding-top' ) ) || 0,
                        intContentPaddingBottom = parseInt( window.getComputedStyle( this ).getPropertyValue( 'padding-bottom' ) ) || 0;
                    // console.log( intContentPaddingTop, intNavLocalHeight, intContentPaddingBottom )
                    return intContentPaddingTop + intNavLocalHeight + intContentPaddingBottom;
                } );
            }
        }
    },
    util: {
        init: function () {
            jQuery( '.js-e-nav-pageicon-language' ).each( function () {
                var jThis = jQuery( this ),
                    arrClassList = jThis.parent().attr( 'class' ).split( ' ' ),
                    strLangISO = '';
                for ( var c = 0; c < arrClassList.length; c++ ) {
                    if ( arrClassList[ c ].indexOf( 'icon-flag-' ) === 0 ) {
                        strLangISO = arrClassList[ c ].split( '-' )[ 2 ];
                        jThis.css( 'background-image', 'url(' + e4.settings.nav.flagCDN + strLangISO + '.svg)' );
                    }
                }
            } );
        }
    }
};

