e4.cookies = {
    init: function () {
        if ( document.cookie.indexOf( 'Dynamicweb.CookieOptInLevel' ) === -1 ) {
            jQuery( '.js-e-cookie' ).removeAttr( 'hidden' );
            e4.cookies.bindEvents();
        }
    },
    bindEvents: function () {
        jQuery( '.js-e-cookie' ).find( '.js-e-cookie-accept-btn' ).on( 'click', function ( event ) {
            event.preventDefault();
            jQuery.get( e4.settings.cookieAcceptLink );
            jQuery( '.js-e-cookie' ).addClass( 'is-accepted' );
        } );

    }
};

